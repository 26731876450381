import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`400Ft Sled Pull (135/90`}{`#`}{`)`}</p>
    <p>{`200Ft Walking Lunge`}</p>
    <p>{`100 Situps`}</p>
    <p>{`75 KBS (53/35)`}</p>
    <p>{`50 Wall Balls`}</p>
    <p>{`25 Calorie Assault Bike`}</p>
    <p>{`for time.`}</p>
    <h4><strong parentName="h4"><em parentName="strong">{`*`}{`The CrossFit Open starts February 21st! To prepare we’ll be throwing in some past Open WOD’s in our class programming. Register for the Open at: Games.CrossFit.com.`}</em></strong></h4>
    <h4><strong parentName="h4">{`*`}{`CrossFit the Ville’s Barbell Club will start back up today at 11:15.  If you’d like to improve your Clean & Jerk and Snatch these classes are for you.`}</strong>{` `}<strong parentName="h4">{`Classes are an hour and a half each and offered Saturday’s (11:15-12:45) and Sunday’s (1:45-3:00) each weekend after our CrossFit classes. `}</strong></h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      